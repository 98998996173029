<template>
  <el-container>
    <el-header>
      <Header></Header>
    </el-header>
    <el-row type="flex" justify="center" class="bg">
      <el-col :md="10" :sm="20">
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <h2>Benvenuto Sul Portale di ESD Italia</h2>
              <h4>Effettua la registratione per cominciare</h4>
            </div>
          </template>
          <el-form label-width="auto" :model="registrationForm" status-icon :rules="rules" ref="registrationForm">
            <el-form-item :label="$t('Email')" prop="email">
              <el-input v-model="registrationForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Nome')" prop="Nome">
              <el-input type="text" v-model="registrationForm.name"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Cognome')" prop="Cognome">
              <el-input type="text" v-model="registrationForm.surname"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Telefono')" prop="Telefono">
              <el-input type="text" v-model="registrationForm.tel"></el-input>
            </el-form-item>
            <el-form-item :label="$t('Telefono 2')" prop="Telefono 2">
              <el-input type="text" v-model="registrationForm.tel2"></el-input>
            </el-form-item>
            <div style="display: flex; justify-content: flex-end;">
              <el-button block type="primary" @click="submit">Registrati</el-button>
            </div>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-footer>
      <Footer></Footer>
    </el-footer>
  </el-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {getNewUserFields} from "../static/formFields";

export default {
  components: {Header, Footer},
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t("login.callback.username")));
      } else {
        if (this.registrationForm.email !== '') {
          this.$refs.registrationForm.validateField("email");
        }
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if(value === ''){
        callback(new Error(this.$t("inserisci un nome")));
      }
      else{
        callback();
      }
    }
    return {
      registrationForm: [],
      rules: {
        email: [
          {validator: validateEmail, trigger: 'blur'}
        ],
        name:[
          {validator: validateName, trigger:'blur'}
        ],
        surname:[
          {validator: validateName, trigger:'blur'}
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["isLogged", "isFirstAccess"])
  },
  methods: {
    ...mapActions(["registerUserBase"]),
    async submit() {
      console.log(this.registrationForm);
      await this.registerUserBase({...this.registrationForm});
      this.$message.success("Registrazione completata, verifica l'email!");
    },
    async created() {
      this.registrationForm = getNewUserFields();
    },
    goTo(name) {
      this.$router.push({name})
    },
  }
}
</script>

<style scoped>
.bg {
  height: 100vh;
  background: #ededed;
  width: 100%;
  align-items: center;
}
</style>
